<template>
  <div
    v-ada-modal="{ show: addrshow, first: () => { $refs.upperTitle.focus() } }"
    class="j-goods-detail-address-upper c-address-upper-store"
    :class="addrshow ? 'mshe-z-transform2' : 'mshe-z-bottom-transform2 upper-bottom-transform2'"
  >
    <div
      ref="upperWrap"
      class="c-upper-wrap"
    >
      <header
        ref="upperHeader"
        class="common-address-header store-search"
      >
        <section class="address-header-inner">
          <span
            v-enterkey
            class="header-close"
            tabindex="0"
            role="button"
            @click="clickCancel"
          > <i class="iconfont icon-close"></i> </span>
          <p
            v-if="isUseSelectAddress"
            ref="upperTitle"
            class="header-title"
            tabindex="0"
            role="heading"
          >
            取貨地址
          </p>
          <div
            v-if="!isUseSelectAddress"
            class="header-content"
          >
            <s-tab
              v-model="tabSelectActive"
              class="header-content-tabs"
            >
              <s-tab-item 
                v-for="item in tabsConfig"
                :id="item.key"
                :key="item.key"
              >
                {{ item.title }}
              </s-tab-item>
            </s-tab>
          </div>
        </section>
        <template v-if="tabSelect === 'storeId'">
          <section class="address-header-search store-search">
            <div class="search-input">
              <i class="iconfont icon-search01"></i>
              <input
                ref="searchStoreInputRef"
                v-model.trim="searchStoreValue"
                v-enterkey
                type="text"
                placeholder="搜索門店名稱/門店號"
                @click="handleSearchStoreByInfo"
                @input="handleSearchStoreByInfo"
                @focus="searchFocus = true"
                @blur="searchBlur"
              />
              <i
                v-if="searchStoreValue && searchFocus"
                class="iconfont icon-clear1"
                @click.stop.prevent="clearSearchStoreInput"
              >
              </i>
            </div>
          </section>
        </template>
        <template v-if="tabSelect === 'address'">
          <section class="address-header-search">
            <div class="search-input">
              <i class="iconfont icon-search01"></i>
              <input
                ref="searchInput"
                v-model="searchValue"
                type="text"
                :placeholder="options[type].search"
                @focus="searchFocus = true"
                @blur="searchBlur"
              />
              <i
                v-if="searchValue && searchFocus"
                class="iconfont icon-clear1"
                @click.stop.prevent="clearSearchInput"
              >
              </i>
            </div>
          </section>
          <section class="address-header-tab">
            <div
              class="tab-wrapper j-ada-cascader-tab"
              role="tablist"
              :aria-activedescendant="`ada-cascader-tab-${type}`"
            >
              <template v-for="option in options">
                <span
                  v-if="option.subs.length"
                  :id="`ada-cascader-tab-${option.id}`"
                  :key="option.id"
                  v-enterkey
                  class="j-tab-item j-ada-cascader-tabitem"
                  :tabindex="type == option.id ? 0 : -1"
                  role="tab"
                  :aria-selected="type == option.id"
                  :aria-labelledby="`ada-cascader-label-${option.id}`"
                  :aria-controls="`ada-cascader-list-${option.id}`"
                  :class="{ 'has-value': option.value, selected: type == option.id }"
                  @click="tabNavChange(option.id)"
                >
                  {{ option.value.replace('***', '') || option.placeholder }}
                </span>
              </template>
            </div>
          </section>
        </template>
      </header>
      <div
        v-show="tabSelect === 'storeId'" 
        v-infinite-scroll="loadMore" 
        class="store-list-content" 
        infinite-scroll-disabled="nodata"
        infinite-scroll-distance="50"
      >
        <div 
          v-for="item in searchStoreList"
          :key="item.service_store_id + item.store_id" 
          class="store-list-item"
          @click.stop="handleSelectStore(item)"
        >
          <span class="item-title">{{ item.store_name }}{{ item.service_store_id ? `-${item.service_store_id}` : '' }}</span>
          <p class="item-desc">
            {{ item.city }}{{ item.district }}{{ item.street }}{{ item.address1 }}
          </p>
        </div>
        <s-loading :show="showPullupLoading" />
      </div>
      <template v-if="tabSelect === 'address'">
        <div
          v-if="dataList.length"
          ref="upperList"
          class="c-upper-list has-search"
        >
          <div
            v-for="(item1, index1) in shortcutList"
            :key="index1"
            :ref="'j-letter-' + index1"
            class="addr-upper-item addr-area-item"
            :class="['j-letter-' + index1]"
          >
            <ul class="upper-list">
              <template v-for="(item2, index2) in dataList">
                <li
                  v-if="item2 && item1 == getFirstLetter(item2.substr(0, 1))"
                  :key="index2"
                  :ref="'j-item-' + item2"
                  v-enterkey
                  :class="{ selected: item2 == checkedValue, 'j-item-selected': item2 == checkedValue }"
                  tabindex="0"
                  :aria-selected="item2 == checkedValue"
                  @click="selectTarget(item2)"
                >
                  {{ item2.replace('***', '') }} <i
                    v-if="item2 == checkedValue"
                    class="iconfont icon-normal-checked"
                  ></i>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div
          v-if="isShortcut"
          class="c-upper-silder"
          @touchstart.stop.prevent="onShortcutTouchStart"
          @touchmove.stop.prevent="onShortcutTouchMove"
          @touchend.stop.prevent="onShortcutTouchEnd"
        >
          <ul class="addr-shortcut-list">
            <li
              v-for="(item, index) in shortcutList"
              :key="index"
              :ref="'j-shortcut-' + index"
              :data-index="index"
            >
              <span
                class="shortcut-item"
                :class="{ selected: index == shortcutIndex && shortcutTouch }"
                :data-index="index"
              >{{ item }}</span>
            </li>
          </ul>
        </div>
        <div
          v-show="shortcutTouch"
          ref="shortcutSelected"
          class="shortcut-selected"
        >
          <img
            v-if="shortcutTouch"
            :src="lang.public_cdn + '/pwa_dist/images/combined_shape-6254232b71.png'"
            alt=""
          /> <span class="name">{{ shortcutValue }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Tab, TabItem } from '@shein/sui-mobile'
import { configTrival } from 'public/src/pages/common/ada/a11y'
import cnchar from 'cnchar'
import trad from 'cnchar-trad'
import { stringifyQueryString, debounce } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

cnchar.use(trad)
const ANCHOR_HEIGHT = 18
const { lang, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'AddressCascaderStore',
  components: {
    's-tab': Tab,
    's-tab-item': TabItem
  },
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    },
    addrshow: {
      type: Boolean,
      default: false
    },
    isShortcut: {
      type: Boolean,
      default: true
    },
    tabsConfig: {
      type: Array,
      default: () => ([])
    },
    storeType: {
      type: [Number, String],
      default: 1
    },
    tabSelect: {
      type: String,
      default: 'storeId'
    }
  },
  data () {
    return {
      lang: {
        lang: lang,
        public_cdn: PUBLIC_CDN
      },
      shortcutIndex: 0,
      shortcutTouch: false, // shortcut是否获取焦点
      searchValue: '',
      searchFocus: false,
      clickClearFlag: false,
      searchStoreValue: '', // 搜索值
      searchStoreList: [], // 搜索出来的门店列表
      pageNumber: 1, // 分页页数
      searchStoreTotal: 0, // 总数
      showPullupLoading: false // 加载更多loading状态
    }
  },
  computed: {
    dataList () {
      let subs = this.type && this.options[this.type].subs || []
      // 按照中文排序
      subs = subs.sort((prev, next) => next.localeCompare(prev))
      return subs
    },
    shortcutList () {
      let array = this.dataList.map(item => {
        return this.getFirstLetter(item.substr(0, 1))
      })
      array = [...new Set(array)].sort((prev, next) => {
        if (prev < next) {
          return -1
        } else if (prev === next) {
          return 0
        } else {
          return 1
        }
      })
      if (array.includes('#')) {
        const letterSet = new Set(array)
        letterSet.delete('#')
        array = [...letterSet.add('#')]
      }
      return array
    },
    shortcutValue () {
      return this.shortcutIndex == -1 ? '✤' : this.shortcutList[this.shortcutIndex]
    },
    checkedValue () {
      return this.type && this.options[this.type].value || ''
    },
    tabSelectActive: {
      set (value) {
        this.$emit('update:tabSelect', value)
      },
      get () {
        return this.tabSelect
      }
    },
    isUseSelectAddress () {
      return this.tabsConfig.length === 1 && this.tabsConfig.find(v => v.key === 'address')
    }
  },
  watch: {
    addrshow (value) {
      if (value) {
        document.body.className += ' modal-open'
      } else {
        document.body.className = document.body.className.replace(/\s?modal-open/, '')
      }
      this.searchValue = ''
    },
    searchValue (value) {
      this.scrollToItem(value)
    },
    checkedValue (value) {
      this.$nextTick(() => {
        if (this.tabSelect !== 'address') return
        this.scrollToItem(value)
      })
    },
    'options.subs': {
      handler() {
        if (this.tabSelect !== 'address') return
        this.$nextTick(() => {
          configTrival('cascader')
        })
      },
      deep: true
    },
    type() {
      this.$nextTick(() => {
        $(`#ada-cascader-tab-${ this.type }`).focus()
      })
    }
  },
  created () {
    this.touch = {}
  },
  methods: {
    getFirstLetter (value = '') {
      const char = cnchar.spell(value, 'first')
      return /^[A-Z]*$/.test(char) ? char : '#'
    },
    scrollToItem (value) {
      if (!value) return
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].substr(0, value.length).toUpperCase() == value.toUpperCase()) {
          const id = this.dataList[i]
          this.$refs.upperList.scrollTop = this.$refs['j-item-' + id][0].offsetTop - this.$refs.upperHeader.clientHeight
          break
        }
      }
    },
    onShortcutTouchStart (e) {
      const index = e.target.getAttribute('data-index')
      const firstTouch = e.touches[0]
      this.touch.y1 = firstTouch.pageY
      this.touch.anchorIndex = index
      this.shortcutTouch = true
      this._scrollTo(index)
    },
    onShortcutTouchMove (e) {
      const firstTouch = e.touches[0]
      this.touch.y2 = firstTouch.pageY
      const delta = ((this.touch.y2 - this.touch.y1) / ANCHOR_HEIGHT) | 0
      const index = parseInt(this.touch.anchorIndex) + delta
      const minLength = 0
      if (index >= minLength && index < this.shortcutList.length) {
        this._scrollTo(index)
      }
    },
    onShortcutTouchEnd () {
      this.shortcutTouch = false
    },
    clickCancel () {
      this.$emit('cancel')
    },
    selectTarget (value) {
      this.searchValue = ''
      this.$emit('select', { type: this.type, value })
    },
    tabNavChange (type) {
      this.searchValue = ''
      this.$emit('change', type)
    },
    searchBlur () {
      setTimeout(() => {
        !this.clickClearFlag && (this.searchFocus = false)
      }, 20)
    },
    clearSearchInput () {
      this.clickClearFlag = true
      this.searchValue = ''
      setTimeout(() => {
        this.$refs.searchInput.focus()
        this.clickClearFlag = false
      }, 30)
    },
    /**
     * @description 清除搜索结果
     * @date 2022-02-10 11:21
     */
    clearSearchStoreInput () {
      this.clickClearFlag = true
      this.resetInfo()
      setTimeout(() => {
        this.$refs.searchStoreInputRef.focus()
        this.clickClearFlag = false
      }, 30)
    },
    _scrollTo (index) {
      this.shortcutIndex = index
      const letterItem = index == -1 ? this.$refs['j-letter-common'] : this.$refs['j-letter-' + index][0]
      const shortcutItem = index == -1 ? this.$refs['j-shortcut-common'] : this.$refs['j-shortcut-' + index][0]
      this.$refs.upperList.scrollTop = letterItem.offsetTop - this.$refs.upperHeader.clientHeight
      this.$refs.shortcutSelected.style.top = shortcutItem.getBoundingClientRect().top - 3 + 'px'
    },
    /**
     * @description 开始搜索
     * @date 2022-02-10 10:41
     */
    handleSearchStoreByInfo: debounce({
      func: function() {
        this.pageNumber = 1
        this.searchStoreTotal = 0
        this.searchStoreList = []
        this.postGetStoreAddressByInfo()
      },
      wait: 500
    }),
    /**
     * @description 选中门店
     * @date 2022-02-10 10:41
     */
    handleSelectStore (info) {
      this.$emit('store-select', info)
    },
    /**
     * @description 重置数据
     * @date 2022-02-10 11:22
     */
    resetInfo () {
      this.searchStoreValue = ''
      this.searchStoreList = []
      this.pageNumber = 1
      this.searchStoreTotal = 0
    },
    /**
     * @description 加载更多
     * @date 2022-02-10 11:22
     */
    loadMore () {
      this.pageNumber++
      this.postGetStoreAddressByInfo()
    },
    /**
     * @description FR-7799 根据门店ID或门店地址模糊匹配门店列表
     * @date 2022-02-10 11:22
     */
    async postGetStoreAddressByInfo () {
      const PAGE_SIZE = 50
      if (this.searchStoreValue.length < 2 || (this.searchStoreTotal && this.searchStoreTotal <= this.searchStoreList.length)) return
      // 只有6位数字的才算做门店ID，其他均为门店地址
      const storeIdReg = /^\d{6}$/
      const searchType = storeIdReg.test(this.searchStoreValue) ? 'service_store_id' : 'store_name'
      if (this.loadMoreFlag) return
      this.loadMoreFlag = true
      this.showPullupLoading = true
      const res = await schttp({
        method: 'POST',
        url: '/api/user/addressbook/storeAddressByInfo/get',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: stringifyQueryString({
          queryObj: {
            page_num: this.pageNumber, 
            page_size: PAGE_SIZE, 
            country_id: '209', 
            store_type: this.storeType, 
            [searchType]: this.searchStoreValue
          }
        })
      })
      this.searchStoreTotal = res?.info?.total || 0
      this.searchStoreList = [...this.searchStoreList, ...(res?.info?.list || [])]
      this.showPullupLoading = false
      this.loadMoreFlag = false
    }
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable */
.j-goods-detail-address-upper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    transition: all .5s ease-in-out;
    &.mshe-z-bottom-transform2.upper-bottom-transform2 {
        transform: translate3d(0,130%,999px);
    }
    .c-upper-wrap {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background: #fff;
        border-radius: .53rem .53rem 0 0;
        .common-address-header {
            position: absolute;
            top: 0;
            width: 100%;
            background: #fff;
            z-index: 102;
            .address-header-inner {
                .flexbox;
                .align-center;
                height: 1.2rem;
                .header-title {
                    text-align: center;
                    width: 100%;
                    .font-dpr(34px);
                    font-weight: bold;
                    color: #333333;
                }
                .header-content {
                  width: 100%;
                  padding-left: 96/75rem;
                }
                .header-content-tabs {
                  border-bottom: none;
                }
                .header-close {
                    position: absolute;
                    float: left;
                    padding: .2rem .4rem;
                    .icon-close {
                        .font-dpr(32px);
                    }
                }
            }
            .address-header-tab {
                .flexbox;
                .align-center;
                height: 1.17333rem;
                white-space: nowrap;
                overflow-x: scroll;
                width: 100%;
                padding: 0.3467rem .32rem .3733rem/*rtl:ignore*/;
                border-bottom: 1px solid #e5e5e5/*rtl:ignore*/;
                &::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                }
                .j-tab-item {
                    color: #666666;
                    .font-dpr(28px);
                    height: 1.16666rem;
                    line-height: 1.16666rem;
                    font-weight: bold;
                    .margin-r(.53333rem);
                    display: inline-block;
                    &.selected {
                        color: #222;
                        border-bottom: 2px solid #222;
                    }
                }
            }
            .address-header-search {
                position: relative;
                height: 1.49333rem;
                padding: 0 .32rem;
                .flexbox;
                width: 100%;
                box-sizing: border-box;
                border-bottom: 1px solid #e5e5e5;
                .padding-r(0.6rem);
                &.store-search {
                  position: relative;
                  &:after {
                    content: '';
                    position: absolute;
                    bottom: -0.32rem;
                    right: 0;
                    width: 100%;
                    height: 0.32rem;
                    background: #F6F6F6;
                  }
                }
                .search-input {
                    position: relative;
                    flex: 1;
                    padding: 0;
                    .icon-search01 {
                        position: absolute;
                        .left(.26666rem);
                        top: .42333rem;
                        .font-dpr(32px);
                        z-index: 1000;
                    }
                    .icon-clear1 {
                        position: absolute;
                        .right(.26666rem);
                        top: .42333rem;
                        .font-dpr(32px);
                        z-index: 1000;
                        color: #ccc;
                    }
                    input {
                        width: 100%;
                        height: .853333rem;
                        margin: .32rem 0;
                        border: none;
                        background: #f6f6f6;
                        color: #222;
                        padding: 0 .90666rem;
                        box-sizing: border-box;
                        &::-webkit-input-placeholder {      /* WebKit browsers*/
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &::-moz-placeholder {            /* Mozilla Firefox 19+ */
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &:-moz-placeholder {              /* Mozilla Firefox 4 to 18 */
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &:-ms-input-placeholder {              /* Internet Explorer 10+*/
                            color: #999!important;
                            opacity: 1!important;
                        }
                    }
                }
                .search-cancel {
                    .font-dpr(28px);
                    color: #222;
                    text-transform : capitalize;
                    .margin-l(.32rem);
                    padding-top: .48rem;
                }
            }
            .address-header-login-tip{
                height: 1.08rem;
                background-color: #FCF0D4;
                line-height: 1.08rem;
                padding: 0 .32rem;
                position: relative;
                .flexbox();
                .align-center();
                .space-between();
                .login-tip-content{
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .close-wrap{
                        display: inline-block;
                        width: 1rem;
                        position: absolute;
                        .left(0);
                        .icon-close{
                            .font-dpr(24px);
                            color: #999999;
                            .padding-l(.32rem);
                        }
                    }
                    span{
                        .font-dpr(24px);
                        color: #222;
                        .padding-l(.64rem);
                    }
                }

                button{
                    height: .53333rem;
                    .font-dpr(22px);
                    color: #222;
                    border: 1px solid #333333;
                    background-color: #fcf0d4;
                    padding: 0 .2133rem;
                }
            }
        }
        .c-upper-list {
            padding: 2.34666rem 0 1rem;
            height: 100%;
            overflow-y: scroll;
            box-sizing: border-box;
            &.has-search {
                padding: 3.86rem 0 1rem;
            }
            &.has-login {
                padding: 3.45rem 0 1rem;
            }
            &.has-search-login {
                padding: 4.9rem 0 1rem;
            }
            .empty-tips {
                text-align: center;
            }
            .addr-upper-item {
                .group-title {
                    .font-dpr(28px);
                    background: #F6F6F6;
                    padding: .03rem .32rem;
                    font-weight: bold;
                    &.country-group {
                        background: #fff;
                        padding-top: .32rem;
                    }
                }
                .upper-list {
                    padding: 0 .32rem  .32rem;
                    li {
                        position: relative;
                        .font-dpr(28px);
                        padding: .32rem 0;
                        border-bottom: 1px solid #e5e5e5;
                        color: #333;
                        .icon-normal-checked {
                            position: absolute;
                            .font-dpr(36px);
                            .right(0.32rem);
                            .left(auto);
                            font-weight: normal;
                            top: .2rem;
                            color: @color_brand;
                        }
                        &.disabled {
                            color: #ccc;
                        }
                        &.selected {
                            font-weight: bold;
                        }
                    }
                }
                &.addr-area-item {
                    .upper-list {
                        li {
                            padding: .32rem 0;
                            .margin-r(.18rem);
                            .icon-normal-checked {
                                position: absolute;
                                .font-dpr(36px);
                                .right(0.32rem);
                                font-weight: normal;
                                top: .2rem;
                            }
                        }
                    }
                }
            }
        }
        .c-upper-silder {
            position: absolute;
            top: 50%;
            .right(0);
            z-index: 2999;
            transform: translate3d(0,-50%,2999px);
            overflow-y: hidden;
            text-align: center;
            font-size: 11px;
            background-color: #fff;
            .addr-shortcut-list {
                li {
                    .padding-l(.08rem);
                    .padding-r(.08rem);
                    color: #9b9b9b;
                    .shortcut-item {
                        display: inline-block;
                        height: .42666rem;
                        width: .42666rem;
                        line-height: .42666rem;
                        .font-dpr(26px);
                        &.selected {
                            background: #222;
                            border-radius: 50%;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .shortcut-selected {
            position: fixed;
            .font-dpr(40px);
            color: #fff;
            .right(.53333rem);
            width: .90666rem;
            height: .64rem;
            line-height: .64rem;
            .name {
                position: relative;
                .padding-l(.16rem);
            }
            img {
                position: absolute;
                width: 100%;
            }
        }
    }
    &.c-address-upper-store {
		.c-upper-wrap {
			background: #F6F6F6;
			.common-address-header {
				.address-header-search {
					.padding-r(.32rem);
					border: none;
					.border-dpr(border-top, 2px, #e5e5e5)
				}
				.address-header-tab {
					position: relative;
					padding: 0;
					border: none;
					.border-dpr(border-bottom, .64rem, #F6F6F6);
					overflow: hidden;
					.j-tab-item {
						height: 1.167rem;
						line-height: 1.167rem;
						&.selected {
							color: @sui_color_highlight;
							border: none; 
						}
					}
					.tab-wrapper {
						padding: 0 .32rem;
						width: 100%;
						height: 1.1667rem;
						overflow-x: scroll;
						overflow-y: hidden;
					}
					// &:after,
					// &:before {
					// 	content: '';
					// 	position: absolute;
					// 	top: 0;
					// 	.right(0);
					// 	width: .32rem;
					// 	height: 100%;
					// 	background-image: linear-gradient(to right, transparent 0%, #fff 100%);
					// }
					// &:before {
					// 	.right(auto);
					// 	.left(0);
					// 	background-image: linear-gradient(to left, transparent 0%, #fff 100%);
					// }
				}
			}
			.c-upper-list {
				.addr-upper-item {
					background: #fff;
					.upper-list {
						.padding-r(0);
						padding-bottom: 0;
					}
					&.addr-area-item {
						.upper-list li {
							.margin-r(0);
							.icon-normal-checked {
								position: static;
							}
						}
					}
					&:last-child {
						.upper-list li:last-child{
							border: none;
						}
					}
				}
			}
			.c-upper-silder {
				background-color: transparent;
			}
		}
		&.ru {
			position: static;
			.c-upper-wrap {
				.common-address-header {
					.address-header-tab {
						height: auto;
					}
					.address-header-inner {
						border-bottom: 1px solid #e5e5e5;
						.header-title {
							color: #767676;
							.font-dpr(32px);
							font-weight: normal;
						}
						.header-close {
							position: static;
							align-self: flex-start;
							padding: .1rem 0 0 0;
							.padding-r(.1rem);
							.icon-close {
								color: #999;
								.font-dpr(20px);
							}
						}
					}
					.address-header-search {
						border: none;
					}
				}
				.c-upper-list {
					padding-bottom: 1.48rem;
					background: #fff;
					.upper-item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						[class*="iconfont"] {
							.padding-r(.32rem);
						}
					}
					.result-item {
						display: flex;
					}
					.result-content {
						flex: 1;
						.padding-l(.26666rem);
						.title {
							.font-dpr(32px);
							color: #222;
							width: 8.5rem;
							margin-bottom: .08rem;
              .distance {
                .margin-l(.267rem);
                .font-dpr(24px);
                white-space: nowrap;
              }
						}
						.desc {
							.font-dpr(28px);
							color: #666;
							margin-bottom: .21333rem;
						}
						.open-time {
							.font-dpr(24px);
						}
						.child {
							.font-dpr(24px);
							color: #999;
							.padding-l(.57rem);
							.time-list {
								>li {
									.flexbox;
									padding: .08rem 0;
                  color: @sui_color_gray_light1;
									.font-dpr(24px);
                  border: none;
									.date, .time {
										flex: 1;
									}
								}
							}
						}
            .flex {
              display: flex;
              align-items: center;
              .suiiconfont {
                align-self: flex-start;
                color: #222;
                color: #222;
                .font-dpr(30px);
              }
              .info {
                flex: 1;
                .margin-l(.16rem);
								color: @sui_color_gray_dark2;
								.font-dpr(28px);
              }
              &.title {
                justify-content: space-between;
              }
            }
					}
					.oops {
						display: flex;
						flex-direction: column;
						align-items: center;
						padding: 1.4rem .32rem;
						.icon-Rectangle {
							.font-dpr(150px);
						}
						.desc {
							margin-top: .53rem;
							color: #999;
							text-align: center;
						}
					}
				}
				.bottom {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					padding: .16rem .32rem;
					background: #fff;
				}
			}
		}
	}
}

.store-list-content {
  overflow-y: auto;
  height: 100%;
  padding: 230/75rem 24/75rem 1rem;
  .store-list-item {
    padding: 22/75rem 0;
    border-bottom: 1PX solid #E5E5E5;
    &:active {
      opacity: 0.8;
    }
  }
  .item-title {
    .font-dpr(28px);
    line-height: 40/75rem;
    color: #222;
    margin-bottom: 2/75rem;
  }
  .item-desc {
    .font-dpr(24px);
    line-height: 34/75rem;
    color: #767676;
  }
}
/* stylelint-enable */
</style>
