<template>
  <div class="passport-wrap">
    <div 
      v-if="!isSupplement"
      class="passport-title" 
      tabindex="0" 
      :aria-label="language.SHEIN_KEY_PWA_16998"
    >
      <p class="passport-value">
        {{ language.SHEIN_KEY_PWA_16998 }}
      </p>
      <p
        v-if="!isSupplement"
        class="passport-tips"
        v-html="language.SHEIN_KEY_PWA_16997"
      ></p>
    </div>
    <div class="passport-content">
      <input-vue
        :type="'numberValue'"
        :value="numberValue"
        :show="true"
        :max-length="10"
        :label="getFieldLabel('passportNumber')"
        :error-show="error.numberValue.show"
        :error-value="error.numberValue.value"
        :has-help="true"
        :disabled="disabled"
        @help="passportImageShow = true"
        @change="inputChange"
        @blur="inputBlur" 
        @clear="clearInput"
      />

      <input-vue
        :type="'issuePlaceValue'"
        :value="issuePlaceValue"
        :show="true"
        :label="getFieldLabel('passportIssuePlace')"
        :text="language.SHEIN_PWA_KEY_5213"
        :error-show="error.issuePlaceValue.show"
        :error-value="error.issuePlaceValue.value"
        :disabled="disabled"
        @change="inputChange"
        @blur="inputBlur"
        @clear="clearInput"
      />

      <div
        class="address-item focus-value"
        :class="{ 'check-false': error.issueDateValue.show }"
      >
        <label
          class="date-title"
          v-html="getFieldLabel('passportIssueDate')"
        ></label>
        <div class="item-input">
          <select
            v-model="dayValue"
            class="mshe-input adr-select j-adr-passportDay"
            @change="dateChange"
          >
            <option value="">
              {{ language.SHEIN_KEY_PWA_15291 }}
            </option>
            <option
              v-for="(item,index) in dayRange"
              :key="index"
              :value="item.value"
            >
              {{ item.value }}
            </option>
          </select>
          <select
            v-model="monthValue"
            class="mshe-input adr-select j-adr-passportMonth"
            @change="dateChange"
          >
            <option value="">
              {{ language.SHEIN_KEY_PWA_15292 }}
            </option>
            <option
              v-for="(item,index) in monthRange"
              :key="index"
              :value="item.value"
            >
              {{ item.value }}
            </option>
          </select>
          <select
            v-model="yearValue"
            class="mshe-input adr-select j-adr-passportYear"
            @change="dateChange"
          >
            <option value="">
              {{ language.SHEIN_KEY_PWA_15293 }}
            </option>
            <option
              v-for="(item,index) in yearRange"
              :key="index"
              :value="item.value"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <p
          v-if="error.issueDateValue.show"
          class="text-error"
          v-html="error.issueDateValue.value"
        ></p>
      </div>

      <input-vue
        :type="'taxNumberValue'"
        :value="taxNumberValue"
        :show="true"
        :label="getFieldLabel('taxNumber')"
        :error-show="error.taxNumberValue.show"
        :error-value="error.taxNumberValue.value"
        :disabled="disabled"
        @change="inputChange"
        @blur="inputBlur"
        @clear="clearInput"
      />
    </div>
    <!-- 提示 -->
    <LazyMount>
      <s-dialog
        :visible.sync="passportImageShow"
        append-to-body
      >
        <img
          style="width: 100%!important;height: initial!important; /* stylelint-disable-line */"
          :src="lang.PUBLIC_CDN + '/pwa_dist/images/passport_img-c3a06799f3.png'"
          :alt="language.SHEIN_KEY_PWA_16999"
        />
        <template
          slot="footer"
        >
          <s-button
            :type="['primary', 'H72PX']"
            :width="'100%'"
            @click="passportImageShow = false"
          >
            {{ language.SHEIN_KEY_PWA_15146 }}
          </s-button>
        </template>
      </s-dialog>
    </LazyMount>
    <LazyMount>
      <s-dialog
        :visible.sync="passportTipsShow"
        append-to-body
      >
        <div
          class="mshe-text-center"
          tabindex="0"
          v-html="language.SHEIN_KEY_PWA_17007"
        ></div>
        <template
          slot="footer"
        >
          <s-button-group hor>
            <s-button-group-item
              :type="['H72PX']"
              @click="continueCancel"
            >
              {{ language.SHEIN_KEY_PWA_15234 }}
            </s-button-group-item>
            <s-button-group-item
              :type="['primary', 'H72PX']"
              @click="continueSubmit"
            >
              {{ language.SHEIN_KEY_PWA_15574 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </LazyMount>
    <LazyMount>
      <s-dialog
        :visible.sync="autoUpdateShow"
        append-to-body
      >
        <div
          class="mshe-text-center"
          tabindex="0"
          v-html="language.SHEIN_KEY_PWA_17006"
        ></div>
        <template
          slot="footer"
        >
          <s-button-group hor>
            <s-button-group-item
              :type="['H72PX']"
              @click="autoUpdateSubmit(false)"
            >
              {{ language.SHEIN_KEY_PWA_15498 }}
            </s-button-group-item>
            <s-button-group-item
              :type="['primary', 'H72PX']"
              @click="autoUpdateSubmit(true)"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </LazyMount>
  </div>
</template>

<script>
import inputVue from './input.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getControlNumber } from './config'
import { isObject } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { LazyMount } from '@shein/sui-mobile'

dayjs.extend(utc)
daEventCenter.addSubscriber({ modulecode: '2-5' })
const { langPath, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'PassportVue',
  components: {
    inputVue,
    LazyMount
  },
  props: {
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    operateType: {
      type: String,
      default: ''
    },
    // 默认宅配
    addressType: {
      type: String,
      default: 'homeDelivery'
    },
    disabled: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data () {
    return {
      lang: {
        langPath,
        PUBLIC_CDN
      },
      passportInfo: {
        passport_number: '',
        issue_place: '',
        issue_date: '..'
      },
      // 护照操作类型
      operationType: 'add',
      numberValue: '',
      issuePlaceValue: '',
      dayValue: '',
      monthValue: '',
      yearValue: '',
      taxNumberValue: '',
      passportImageShow: false,
      passportTipsShow: false,
      autoUpdateShow: false,
      error: {
        numberValue: {
          value: '',
          show: false,
          flag: false
        },
        issuePlaceValue: {
          value: '',
          show: false,
          flag: false
        },
        taxNumberValue: {
          value: '',
          show: false,
          flag: false
        },
        issueDateValue: {
          value: '',
          show: false,
          flag: false
        }
      },
      AddressCheckRule: null,
      fieldRequired: {
        passportNumber: false,
        passportIssuePlace: false,
        passportIssueDate: false,
        taxNumber: false
      },
      otherCheckCopywriter: {}
    }
  },
  computed: {
    isSupplement () {
      return this.operateType == 'supplement'
    },
    issueDateValue () {
      if (this.dayValue && this.monthValue && this.yearValue) {
        return this.dayValue + '.' + this.monthValue + '.' + this.yearValue
      } else {
        return ''
      }
    },
    dayRange () {
      return this.caculateDayRange(this.monthValue, this.yearValue)
    },
    monthRange () {
      const array = []
      for (let i = 1; i <= 12; i++) {
        const val = ('0' + i).slice(-2)
        array.push({
          label: val,
          value: val
        })
      }
      return array
    },
    yearRange () {
      const array = []
      const year = new Date().getFullYear()
      for (let i = year; i >= 1940; i--) {
        const val = i + ''
        array.push({
          label: val,
          value: val
        })
      }
      return array
    }
  },
  created () {
    if (!['edit'].includes(this.operateType)) {
      this.getPassport()
    }
  },
  methods: {
    getFieldLabel(type) {
      const isRequired = this.fieldRequired[type]
      const labels = {
        passportNumber() {
          if (this.AddressCheckRule) {
            return `${ isRequired ? '*' : '' }${ this.language.SHEIN_KEY_PWA_16999 }`
          }
          return `*${ this.language.SHEIN_KEY_PWA_16999 }`
        },
        passportIssuePlace() {
          if (this.AddressCheckRule) {
            return `${ isRequired ? '*' : '' }${ this.language.SHEIN_KEY_PWA_16994 }`
          }
          return `*${ this.language.SHEIN_KEY_PWA_16994 }`
        },
        passportIssueDate() {
          if (this.AddressCheckRule) {
            return `${ isRequired ? '*' : '' }${ this.language.SHEIN_KEY_PWA_17039 }`
          }
          return `*${ this.language.SHEIN_KEY_PWA_17039 }`
        },
        taxNumber() {
          if (this.AddressCheckRule) {
            return `${ isRequired ? '*' : '' }${ this.language.SHEIN_KEY_PWA_17001 }`
          }
          return `*${ this.language.SHEIN_KEY_PWA_17001 }`
        }
      }
      const action = labels[type]
      return (typeof action === 'function') ? action.call(this) : action
    },
    setError({ type = '', flag = true, tips = '' }) {
      if (!type) {
        return
      }
      this.error[type].value = tips
      this.error[type].show = !flag
      this.error[type].flag = flag
    },
    caculateDayRange (month, year) {
      const array = []
      const m = +month; const y = +year; let range = 31
      if ([0, 1, 3, 5, 7, 8, 10, 12].indexOf(m) > -1) {
        range = 31
      } else if ([4, 6, 9, 11].indexOf(m) > -1) {
        range = 30
      } else if (m == 2 && this.leapYear(y)) {
        range = 29
      } else {
        range = 28
      }
      for (let i = 1; i <= range; i++) {
        const val = ('0' + i).slice(-2)
        array.push({
          label: val,
          value: val
        })
      }
      return array
    },
    fomatDate (val) {
      return ('0' + val).slice(-2)
    },
    async setData (data) {
      const { passportNumber, passportIssuePlace, taxNumber, passportIssueDate } = data || {}
      const isSetInfo = passportNumber || passportIssuePlace || taxNumber || passportIssueDate
      await this.getPassport(!isSetInfo)
      if (isSetInfo) {
        if (passportNumber) {
          this.passportInfo.passport_number = passportNumber
          this.numberValue = passportNumber.replace(/^.{6}/, '******') || ''
        } else {
          this.numberValue = ''
        }
        this.issuePlaceValue = passportIssuePlace
        this.taxNumberValue = taxNumber
        if (passportIssueDate) {
          if (typeof passportIssueDate === 'number') {
            const date = new Date(passportIssueDate * 1000)
            this.yearValue = date.getFullYear() + ''
            this.monthValue = this.fomatDate(date.getMonth() + 1)
            this.dayValue = this.fomatDate(date.getDate())
          } else {
            const date = passportIssueDate.split('.')
            if (date.length == 3) {
              this.dayValue = this.fomatDate(date[0])
              this.monthValue = this.fomatDate(date[1])
              this.yearValue = date[2]
            }
          }
        } else {
          this.dayValue = ''
          this.monthValue = ''
          this.yearValue = ''
        }
      }
      this.check()
    },
    async getPassport(isSet = true) {
      const res = await schttp({
        method: 'POST',
        url: '/api/user/addressbook/passport/get',
      })
      if (res.code == 0) {
        var data = res.info && res.info.passport
        if (typeof data !== 'undefined') {
          if (data && isObject(data)) {
            this.operationType = 'edit'
            this.passportInfo = {
              ...this.passportInfo,
              issue_place: data.issue_place || '',
              issue_date: data.issue_date || '',
              tax_number: data.tax_number || '',
              passport_number: data.passport_number || ''
            }
            if (isSet) {
              this.setPassportData()
            }
          } else {
            this.operationType = 'add'
          }
        }
      }
    },
    setPassportData: function () {
      var data = this.passportInfo
      this.numberValue = data.passport_number && data.passport_number.replace(/^.{6}/, '******') || ''
      this.issuePlaceValue = data.issue_place
      this.taxNumberValue = data.tax_number
      var date = data.issue_date ? data.issue_date.split('.') : []
      if (date.length == 3) {
        this.dayValue = this.fomatDate(date[0])
        this.monthValue = this.fomatDate(date[1])
        this.yearValue = date[2]
        this.dateChange()
      }
    },
    inputChange (type, value) {
      this[type] = value
      if (type == 'numberValue' && this.numberValue.indexOf('*') > -1) {
        this.numberValue = ''
      }
    },
    inputBlur (type) {
      if (type === 'numberValue') {
        this.checkNumber()
      } else if (type === 'taxNumberValue') {
        this.checkTaxNumber()
      } else if (type === 'issuePlaceValue') {
        this.checkIssuePlace()
      }
    },
    clearInput (type) {
      this[type] = ''
    },
    dateChange: function () {
      let flag = this.checkDate(this.dayValue, this.monthValue, this.yearValue, this.dayRange)
      this.setError({ type: 'issueDateValue', flag, tips: this.language.SHEIN_KEY_PWA_16995 })
      this.checkNumber()
    },
    check () {
      const checks = ['checkNumber', 'checkTaxNumber', 'checkIssuePlace', 'checkIssueDate']
      let flags = checks.map(item => {
        return this[item]()
      })
      return flags.every(flag => flag)
    },
    checkNumber: function () {
      if (this.AddressCheckRule) {
        const val = this.getPassportNumber()
        let flag = this.AddressCheckRule.passportNumber({ type: 'numberValue', value: val, instance: this })
        if (flag) {
          const ruPassportError = this.otherCheckCopywriter.ruPassportError
          if (ruPassportError) {
            flag = !this.yearValue || Math.abs(Number(val.slice(2, 4)) - Number(this.yearValue.slice(2))) <= 3
            this.setError({ type: 'numberValue', flag, tips: ruPassportError })
          }
        }
        return flag
      }
      return true
    },
    /**
     * 
     * 12位数字
     * 不能连续12个0
     * 
     * */ 
    checkControlNumber() {
      const val = this.taxNumberValue
      const x = getControlNumber({ inn: val, multiplyNumbers: [7, 2, 4, 10, 3, 5, 9, 4, 6, 8] })
      const y = getControlNumber({ inn: val, multiplyNumbers: [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8] })
      return (x == val.slice(10, 11)) && (y == val.slice(11))
    },
    checkTaxNumber () {
      if (this.AddressCheckRule) {
        let flag = this.AddressCheckRule.taxNumber({ type: 'taxNumberValue', value: this.taxNumberValue, instance: this })
        if (flag) {
          const ruTaxError = this.otherCheckCopywriter.ruTaxError
          if (ruTaxError) {
            const val = this.taxNumberValue
            flag = !(/^0{12}$/.test(val)) && this.checkControlNumber()
            this.setError({ type: 'taxNumberValue', flag, tips: ruTaxError })
          }
        }
        return flag
      }
      return true
    },
    checkIssuePlace () {
      if (this.AddressCheckRule) {
        return this.AddressCheckRule.passportIssuePlace({ type: 'issuePlaceValue', value: this.issuePlaceValue, instance: this })
      }
      return true
    },
    checkIssueDate () {
      if (this.AddressCheckRule) {
        return this.AddressCheckRule.passportIssueDate({ type: 'issueDateValue', value: this.issueDateValue, instance: this })
      }
      return true
    },
    checkDate: function (day, month, year, range) {
      let flag = true
      if (day && month && year) {
        if (+day <= range.length) {
          const nowDate = new Date()
          const nowYear = nowDate.getFullYear()
          const nowMonth = nowDate.getMonth() + 1
          const nowDay = nowDate.getDate()
          flag = !!(+year < nowYear || +month < nowMonth || (+month == nowMonth && +day <= nowDay))
        } else {
          flag = false
        }
      }
      return flag
    },
    checkBeforSubmit (flag) {
      if (!this.check() || !this.checkDate(this.dayValue, this.monthValue, this.yearValue, this.dayRange)) {
        return false
      } else if (!this.numberValue && !this.issuePlaceValue && !this.dayValue && !this.monthValue && !this.yearValue && !this.taxNumberValue) {
        return true
      } else if (flag && !(this.numberValue && this.issuePlaceValue && this.dayValue && this.monthValue && this.yearValue && this.taxNumberValue)) {
        this.passportTipsShow = true
        daEventCenter.triggerNotice({
          daId: '2-5-2'
        })
        return false
      } else if (flag && (this.getPassportNumber() != this.passportInfo.passport_number || this.issuePlaceValue != this.passportInfo.issue_place || this.issueDateValue != this.passportInfo.issue_date || this.taxNumberValue != this.passportInfo.tax_number)) {
        this.autoUpdateShow = true
        daEventCenter.triggerNotice({
          daId: '2-5-5'
        })
        return false
      } else {
        return true
      }
    },
    async  passportSubmit () {
      if (this.checkNumber() && this.checkDate(this.dayValue, this.monthValue, this.yearValue, this.dayRange) && this.checkTaxNumber()) {
        var data = {
          passportNumber: this.numberValue.indexOf('*') > -1 ? this.passportInfo.passport_number : this.numberValue,
          issuePlace: this.issuePlaceValue || '',
          issueDate: this.issueDateValue,
          taxNumber: this.taxNumberValue
        }
        if (this.operationType == 'add') {
          const res = await schttp({
            method: 'POST',
            url: '/api/user/addressbook/passport/create',
            data
          })
          if (res.code == 0) {
            this.passportInfo.passport_number = data.passportNumber
            this.passportInfo.issue_place = data.issuePlace
            this.passportInfo.issue_date = data.issueDate
            this.passportInfo.tax_number = data.taxNumber
            this.operationType = 'edit'
          }
        } else if (this.operationType == 'edit') {
          const res = await schttp({
            method: 'POST',
            url: '/api/user/addressbook/passport/update',
            data
          })
          if (res.code == 0) {
            this.passportInfo.passport_number = data.passportNumber
            this.passportInfo.issue_place = data.issuePlace
            this.passportInfo.issue_date = data.issueDate
            this.passportInfo.tax_number = data.taxNumber
          }
        }
      }
    },
    leapYear (Year) {
      if (((Year % 4) == 0) && ((Year % 100) != 0) || ((Year % 400) == 0)) {
        return true
      } else {
        return false
      }
    },
    continueCancel () {
      if (!this.passportTipsShow) return
      this.passportTipsShow = false
      daEventCenter.triggerNotice({
        daId: '2-5-3'
      })
    },
    continueSubmit () {
      if (!this.passportTipsShow) return
      // 护照信息不完整
      this.passportTipsShow = false
      this.$emit('submit')
      daEventCenter.triggerNotice({
        daId: '2-5-4'
      })
    },
    autoUpdateSubmit (flag) {
      if (!this.autoUpdateShow) return
      // 同步信息到个人中心
      this.autoUpdateShow = false
      flag && this.passportSubmit()
      this.$emit('submit')
      daEventCenter.triggerNotice({
        daId: flag ? '2-5-7' : '2-5-6'
      })
    },
    getPassportNumber () {
      return this.numberValue.indexOf('*') > -1 ? this.passportInfo.passport_number : this.numberValue
    }
  }
}
</script>
<style lang="less">
  .passport-supplement-img {
    margin-top: -80%;
  }
//   .j-passport-modal {
// 	.modal-content {
// 		width: 7.6rem;
// 		.modal-body {
// 			padding: 0.6rem 0.4rem 0.79rem;
// 		}
// 		.modal-footer {
// 			padding: .32rem;
// 			.modal-btn {
// 				font-size: 14px;
// 				background: #222;
// 				color: #fff;
// 				.font-dpr(28px);
// 				&:first-child {
// 					.margin-r(.32rem);
// 				}
// 				&.modal-btn-black {
// 					background: #fff !important; /* stylelint-disable-line declaration-no-important */
// 					color: #222;
// 					.border-dpr(border, 2px, #ccc);
// 				}
// 			}
// 		}
// 	}
// }
</style>
