<template>
  <div
    v-if="isSupportInfoShow"
    class="auxiliary-policy"
  >
    <div class="auxiliary-policy__title">
      <i class="lock suiiconfont sui_icon_Privacy_16px"></i>
      <span class="txt">{{ htmlDecode({ text: language.SHEIN_KEY_PWA_23550 }) }}</span>
    </div>
    <div class="auxiliary-policy__detail">
      {{ language.SHEIN_KEY_PWA_23551 }}
    </div>
  </div>
</template>
<script>
import { htmlDecode } from '@shein/common-function'
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  props: {
    language: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isSupportInfoShow: false
    }
  },
  async created() {
    await this.getABTInfo()
    if (this.isSupportInfoShow) {
      daEventCenter.triggerNotice({
        daId: '2-5-21',
        extraData: {
          name: 'expose_auxiliary_decision_info'
        }
      })
    }
  },
  methods: {
    htmlDecode,
    async getABTInfo () {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const { SupportInfo } = await abtservice.getUserAbtResult({
        newPosKeys: 'SupportInfo',
      })
      this.isSupportInfoShow = SupportInfo?.param?.SupportInfoShow === 'Show'
    },
  },
}
</script>
<style lang="less" scoped>
  .auxiliary-policy {
    padding: 0.32rem;
    margin-top: 0.267rem;
    background: @sui_color_white;
    &__title {
      display: flex;
      align-items: center;
      color: @sui_color_safety;
      .txt {
        margin-left: .106rem;
        color: @sui_color_main;
        font-size: 14px;
      }
    }
    &__detail {
      margin-top: .213rem;
      color: @sui_color_gray_dark3;
      font-size: 12px;
      text-align: left;
    }
  }
</style>
