import { LoginPatterRegex } from 'public/src/pages/login/util.js'
class AddressStoreCheck {
  constructor (instance = null, language = { tips: {} }) {
    this.addressObj = instance
    this.language = language
    this.addressTest = {
      reg_name_225: /^[A-Za-z0-9_@#\s]{2,30}$/,
      reg_tel_82: /^\d{5,30}$/,
      reg_tel_225: /^\d{5,16}$/,
      reg_tel_137: /^\d{5,25}$/,
      reg_tel_198: /^\d{5,13}$/,
      reg_tel_13: /^\d{9,10}$/,
      reg_name_178: /^[a-zA-Z0-9\s]{2,100}$/,
      reg_tel_212: /^\d{10}$/,
      reg_name_en: /^[A-Za-z\s·\.●\-]{2,35}$/,
      reg_name_zh: /^[\u4e00-\u9fa5]{2,10}$/,
      reg_tel: /^09\d{8}$/
    }
  }

  fname () {
    let flag = true
    const { fname, countryId } = this.addressObj
    switch (countryId) {
      case 198:
      case 74:
        if (fname.length < 2 || fname.length > 40) {
          flag = false
          this.addressObj.error.fname.value = this.language.SHEIN_KEY_PWA_15891
        }
        break
      case 82:
      case 13:
        if (fname.length < 2 || fname.length > 20) {
          flag = false
          this.addressObj.error.fname.value = this.language.SHEIN_KEY_PWA_17817
        }
        break
      case 225:
        if (!this.addressTest.reg_name_225.test(fname)) {
          flag = false
          this.addressObj.error.fname.value = this.language.SHEIN_KEY_PWA_15929
        }
        break
      case 178:
        if (fname.length < 1 || fname.length > 40) {
          flag = false
          this.addressObj.error.fname.value = this.language.SHEIN_KEY_PWA_15891.replace('2', '1')
        }
        break
      case 209:
        if (!(this.addressTest.reg_name_en.test(fname) || this.addressTest.reg_name_zh.test(fname))) {
          flag = false
          this.addressObj.error.fname.value = '姓名必须是2-10个繁体中文或2-35个英文字母。'
        }
        break
    }
    this._setAddressErrorValue('fname', flag)
    return flag
  }

  lname () {
    let flag = true
    const { lname, fname, countryId } = this.addressObj
    switch (countryId) {
      case 198:
      case 74:
        if (lname.length < 2 || lname.length > 40) {
          flag = false
          this.addressObj.error.lname.value = this.language.SHEIN_KEY_PWA_15892
        }
        break
      case 82:
        if ((fname + lname).length >= 20) {
          flag = false
          this.addressObj.error.lname.value = this.language.SHEIN_KEY_PWA_17816
        }
        break
      case 225:
        if (!this.addressTest.reg_name_225.test(lname)) {
          flag = false
          this.addressObj.error.lname.value = this.language.SHEIN_KEY_PWA_15930
        }
        break
      case 13:
        if (lname.length < 2 || lname.length > 20) {
          flag = false
          this.addressObj.error.lname.value = this.language.SHEIN_KEY_PWA_17890
        }
        break
      case 178:
        if (lname.length < 1 || lname.length > 40) {
          flag = false  
          this.addressObj.error.lname.value = this.language.SHEIN_KEY_PWA_15892.replace('2', '1')
        }
        break
    }
    this._setAddressErrorValue('lname', flag)
    return flag
  }

  fatherName () {
    const { fatherName = '', countryId } = this.addressObj
    let flag = true
    switch (countryId) {
      case 178:
        if (fatherName.length < 1 || fatherName.length > 40) {
          flag = false
          this.addressObj.error.fatherName.value = this.language.SHEIN_KEY_PWA_16003?.replace('2', '1')
        }
        break
    }
    this._setAddressErrorValue('fatherName', flag)
    return flag
  }

  englishName () {
    const { englishName = '', countryId } = this.addressObj
    let flag = true
    switch (countryId) {
      case 178:
        if (!this.addressTest.reg_name_178.test(englishName)) {
          flag = false
          this.addressObj.error.englishName.value = this.language.SHEIN_KEY_PWA_15947
        }
        break
    }
    this._setAddressErrorValue('englishName', flag)
    return flag
  }

  tel () {
    let flag = true
    const { tel, countryId } = this.addressObj
    switch (countryId) {
      case 74:
      case 178:
        if (!this.addressTest.reg_tel_212.test(tel)) {
          flag = false
          this.addressObj.error.tel.value = this.language.SHEIN_KEY_PWA_14852
        }
        break
      case 82:
        if (!this.addressTest.reg_tel_82.test(tel)) {
          flag = false
          this.addressObj.error.tel.value = this.language.SHEIN_KEY_PWA_15893
        }
        break
      case 225:
        if (!this.addressTest.reg_tel_225.test(tel)) {
          flag = false
          this.addressObj.error.tel.value = this.language.SHEIN_KEY_PWA_15943
        }
        break
      case 13:
        if (!this.addressTest.reg_tel_13.test(tel)) {
          flag = false
          this.addressObj.error.tel.value = this.language?.SHEIN_KEY_PWA_15943?.replace('5', '9')?.replace('16', '10')
        }
        break
      case 198:
        if (!this.addressTest.reg_tel_198.test(tel)) {
          flag = false
          this.addressObj.error.tel.value = this.language?.SHEIN_KEY_PWA_15943?.replace('16', '13')
        }
        break
      case 209:
        if (!this.addressTest.reg_tel.test(tel)) {
          flag = false
          this.addressObj.error.fname.value = '電話號碼必須是以09開頭的10位數字。'
        }
        break
    }
    this._setAddressErrorValue('tel', flag)
    return flag
  }

  standbyTel() {
    const { standbyTel = '', countryId } = this.addressObj
    let flag = true
    switch (countryId) {
      case 209:
        if (!!standbyTel && !this.addressTest.reg_name_178.test(standbyTel)) {
          flag = false
          this.addressObj.error.standbyTel.value = '電話號碼必須是以09開頭的10位數字。'
        }
        break
    }
    this._setAddressErrorValue('standbyTel', flag)
    return flag
  }

  storeAddress () {
    let flag = true
    if (!this.addressObj.storeAddress) {
      flag = false
      this.addressObj.error.storeAddress.value = (this.addressObj.countryId === 209) ? '請選擇地址' : this.language.SHEIN_KEY_PWA_16616
    }
    if(!this.addressObj.extraRuleFlag) return false
    this._setAddressErrorValue('storeAddress', flag)
    return flag
  }

  email() {
    const { email: val } = this.addressObj
    let flag = true
    if (!val) {
      this.addressObj.error.email.value = this.language.SHEIN_KEY_PWA_15589
      flag = false
    } else if(!LoginPatterRegex.test(val)) {
      this.addressObj.error.email.value = this.language.SHEIN_KEY_PWA_15331
      flag = false
    }
    this._setAddressErrorValue('email', flag)
    return flag
  }

  _setAddressErrorValue (type, flag) {
    if (this.addressObj && this.addressObj.error && this.addressObj.error[type]) {
      this.addressObj.error[type].show = !flag
    }
  }
}
export default AddressStoreCheck
