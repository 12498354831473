import { isObject } from '@shein/common-function'

class AddressSensitive {
  // 校验函数和后端字段映射
  #fieldNamesMap = {
    country: 'country',
    first_name: 'fname',
    last_name: 'lname',
    middle_name: 'middleName',
    father_name: 'fatherName',
    english_name: 'englishName',
    state: 'state',
    city: 'city',
    district: 'district',
    street: 'street',
    tax_number: 'taxNumber',
    address1: 'address1',
    address2: 'address2',
    postcode: 'postcode',
    tel: 'tel',
    standby_tel: 'standbyTel',
    national_id: 'nationalId',

    passport_number: 'passportNumber',
    passport_issue_place: 'passportIssuePlace',
    passport_issue_date: 'passportIssueDate',
    birthday: 'birthday',
    store_type_code: 'store_type_code',
  }

  constructor(addressObj, { fields_list = [] }) {
    ['fname', 'lname', 'fatherName', 'englishName', 'tel', 'standbyTel'].forEach(item => {
      const tempObj = AddressSensitive.prototype[item]
      if(tempObj){
        AddressSensitive.prototype[item] = null
      }
    })
    this.addressObj = addressObj
    this.#setCheckMethods({ fields_list })
  }

  // 获取地址组件值
  #getFieldValue(name) {
    return name === 'taxNumber'
      ? this.addressObj?.getTaxNumber?.()
      : this.addressObj[name]
  }

  #setCheckMethods({ fields_list = [] }) {
    for (let i = 0; i < fields_list.length; i++) {
      const {
        language_content,
        sensitive_content = [],
        sensitive_field: field_name,
        sensitive_rule = 1,
      } = fields_list[i]
      const fieldName = this.#fieldNamesMap[field_name]

      AddressSensitive.prototype[fieldName] = (params = {}) => {
        const {
          instance = this.addressObj || {},
          type = fieldName,
          value = String(this.#getFieldValue(fieldName)),
        } = params
        try {
          if (
            !language_content ||
            !sensitive_content.length ||
            !fieldName ||
            !sensitive_rule
          ) {
            return
          }
          if (!value) {
            return
          }
          let isAccord = false
          switch (sensitive_rule) {
            // 包含
            case 1:
              isAccord = sensitive_content.some((item) => {
                return value.toLocaleLowerCase().includes(item.toLocaleLowerCase())
              })
              break
            // 等于
            case 2:
              isAccord = sensitive_content.some((item) => {
                return value == item
              })
              break
            // 不包含
            case 3:
              isAccord = sensitive_content.every((item) => {
                const isInclude = value.toLocaleLowerCase().includes(item.toLocaleLowerCase())
                return !isInclude
              })
              break
          }
          if (isAccord) {
            this.setAddressSensitiveNote({
              instance,
              type,
              text: language_content,
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  setAddressSensitiveNote({
    instance = this.addressObj,
    text = '',
    type,
  } = {}) {
    if (!type) {
      return
    }
    if (isObject(instance?.sensitiveNote)) {
      instance.sensitiveNote[type] = text
    }
  }
}

export default AddressSensitive
