<template>
  <div
    v-if="show"
    class="address-item"
    :class="['j-adr-' + type + '-con', (value || focusFlag) ? 'focus-value' : '', errorShow ? 'check-false' : '',noGap ? 'store-item__nogap' : '']"
  >
    <label
      :id="'j-adr-label-' + type"
      class="item-title"
      @click="itemFocus"
    >
      <span
        :class="{ 'disable-gray': isDisabled }"
        v-html="label"
      ></span>
      <i
        v-if="isDisabled"
        class="iconfont icon-pro"
        @click.stop="prompt"
      ></i>
      <i
        v-if="hasHelp"
        class="iconfont icon-pro"
        @click.stop.prevent="onClickHelp"
      ></i>
    </label>
    <div
      class="item-input"
      :class="{'no-border': noBorder, 'disable-gray': isDisabled }"
    >
      <span
        v-if="areaCode.length || (telEn || telNum)"
        class="new_tel_num"
      >
        <template v-if="areaCode.length">
          <select v-model="telCode">
            <option
              v-for="(code, index) in areaCode"
              :key="code"
              :value="index"
            >
              {{ telEn }} +{{ code }}
            </option>
          </select>
          <i class="iconfont icon-down"></i>
        </template>
        <template v-else-if="telEn || telNum">
          {{ telEn }} {{ telNum }}
        </template>
      </span>
      <div
        class="input-area"
        @click="itemFocus"
      >
        <input
          :ref="type"
          v-model.trim="inputValue"
          :aria-labelledby="'j-adr-label-' + type"
          :type="inputType"
          class="address-input"
          autocomplete="new-password"
          :class="'j-address-' + type"
          :disabled="disabled.includes(type)"
          :maxlength="maxLength"
          placeholder=""
          @input="inputChange" 
          @focus="inputFocus" 
          @blur="inputBlur"
        />
        <input
          v-show="false"
          type="password"
          autocomplete="new-password"
          tabindex="-1"
        />
        <i
          v-if="value && focusFlag"
          class="iconfont icon-clear1"
          @click.stop.prevent="clearInput"
        ></i>
        <i
          v-else-if="hasList"
          class="suiiconfont sui_icon_more_down_16px"
          @click.stop="onClickLeft"
        ></i>
        <p class="text-placeholer">
          <span
            class="title"
            v-html="label"
          ></span>
          <i
            v-if="disabled.includes(type)"
            class="iconfont icon-pro"
            @click.stop="prompt"
          ></i>
          <i
            v-if="hasHelp"
            class="iconfont icon-pro"
            @click.stop.prevent="onClickHelp"
          ></i>
          <span
            class="gray-text"
            v-html="placeholder"
          ></span>
        </p>
      </div>
    </div>
    <p
      v-if="errorShow"
      class="text-error"
      tabindex="0"
      v-html="errorValue"
    ></p>
    <p
      v-if="sensitiveNoteText"
      class="sensitive-color"
      tabindex="0"
      v-html="sensitiveNoteText"
    ></p>
    <p
      v-if="grayText"
      class="text-error gray-color"
      tabindex="0"
      v-html="grayText"
    ></p>
    <p
      v-if="isShowPopoverTips"
      class="c-adr-tip"
      tabindex="-1"
      v-html="popoverTips"
    ></p>
    <slot></slot>
  </div>
</template>


<script>
export default {
  name: 'SelectUpper',
  props: {
    noGap: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'text'
    },
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    errorShow: {
      type: Boolean,
      default: false
    },
    errorValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    telEn: {
      type: String,
      default: ''
    },
    telNum: {
      type: String,
      default: ''
    },
    hasList: {
      type: Boolean,
      default: false
    },
    hasHelp: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 1000
    },
    areaCode: {
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Array,
      default() {
        return []
      }
    },
    bubbleTips: {
      type: String,
      default: ''
    },
    isShowExceed: {
      type: Boolean,
      default: false
    },
    sensitiveNote: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      focusFlag: false,
      inputValue: '',
      telCode: 0
    }
  },
  computed: {
    isDisabled() {
      return this.disabled.includes(this.type)
    },
    popoverTips() {
      return this.bubbleTips
    },
    isShowPopoverTips () {
      return this.isShowExceed && this.bubbleTips
    },
    grayText () {
      return this.text
    },
    sensitiveNoteText () {
      return this.sensitiveNote
    }
  },
  watch: {
    value () {
      this.inputValue = this.value
    }
  },
  mounted () {
    this.inputValue = this.value
  },
  methods: {
    prompt() {
      this.$emit('prompt')
    },
    itemFocus () {
      this.$refs[this.type] && this.$refs[this.type].focus()
    },
    inputFocus () {
      this.focusFlag = true
      this.$emit('focus', this.type)
    },
    inputBlur () {
      setTimeout(() => {
        this.focusFlag = false
        this.$refs[this.type] && this.$refs[this.type].blur()
      }, 20)
      this.$emit('blur', this.type)
    },
    inputChange () {
      const regex = /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu
      if (['fname', 'middleName', 'lname', 'address1', 'address2'].indexOf(this.type) >= 0 && regex.test(this.inputValue)) {
        this.inputValue = this.inputValue.replace(regex, '').replace(/(?:\u200D)/g, '')
      }
      this.$emit('change', this.type, this.inputValue)
    },
    clearInput () {
      this.$emit('clear', this.type)
      setTimeout(() => {
        this.itemFocus()
      }, 30)
    },
    onClickLeft () {
      this.$emit('more', this.type)
    },
    onClickHelp () {
      this.$emit('help', this.type)
    }
  }
}
</script>
<style lang="less" scope>
 .address-item {
  .sensitive-color {
    padding: 0.10666rem 0 0.32rem;
    color: @sui_color_micro_emphasis;
    font-size: 12px;
  }
}
.store-item__nogap {
  margin-bottom: 0.32rem;
  /* stylelint-disable-next-line declaration-no-important */
  margin-top: 0 !important;
}
</style>
