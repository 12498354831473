<template>
  <div class="post-number flex">
    <span
      v-if="storeRegisterCode"
      class="info"
    >
      {{ language.SHEIN_KEY_PWA_22967 }}: {{ desensitizeRegisterCode({ code: storeRegisterCode }) }}
    </span>
    <span
      v-else
      class="info"
    >{{ language.SHEIN_KEY_PWA_22966 }}</span>
    <i
      class="suiiconfont sui_icon_edit_20px"
      @click.stop="toEdit"
    >
    </i>
  </div>
</template>
<script>
export default {
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    storeRegisterCode: {
      type: String,
      default: ''
    }
  },
  methods: {
    desensitizeRegisterCode({ code = '' } = {}) {
      if (!code) {
        return ''
      }
      return code.replace(/^(\d{2})(\d+)(\d{2})$/, (_, p1, p2, p3) => {
        return `${ p1 }${ '*'.repeat(p2.length) }${ p3 }`
      })
    },
    toEdit() {
      this.$emit('edit')
    }
  },
  emits: ['edit']
}
</script>

