<template>
  <div class="c-search">
    <div class="c-search__panel">
      <div class="c-search__input">
        <i class="suiiconfont sui_icon_nav_search_24px"></i>
        <input
          v-model="keywords"
          class="content address-input"
          type="text"
          :placeholder="language.SHEIN_KEY_PWA_17335"
          @input="getSearchList"
          @focus="getSearchList"
        />
        <i
          v-show="keywords"
          v-enterkey
          class="iconfont icon-clear1"
          role="button"
          tabindex="0"
          :aria-label="language.SHEIN_KEY_PWA_14885"
          @click="clear"
        >
        </i>
      </div>
    </div>
    <div
      v-show="isShowLists"
      class="c-search__list"
      :style="{ height: map ? '15.307rem' : '14.187rem' }"
    >
      <div class="c-search__result">
        <ul class="panel">
          <li
            v-for="(item, index) in addressLists"
            :key="index"
            class="item"
            @click="tap(item)"
          >
            <span
              class="text"
              v-html="formatHtml(item.Text)"
            ></span>
          </li>
        </ul>
        <div
          v-if="addressLists.length"
          class="service"
        >
          <img
            class="logo"
            :src="IMG_LINK.google"
            alt="google"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce, isFunction } from '@shein/common-function'
import { GOOGLE_DATA, CACHE } from '../config'
import schttp from 'public/src/services/schttp'
import { keyBy, mapKeys } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const { IMG_LINK } = gbCommonInfo

export default {
  props: {
    countryId: {
      type: [String, Number],
      default: '',
    },
    language: {
      type: Object,
      default() {
        return {}
      },
    },
    map: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IMG_LINK,
      keywords: '',
      isShowLists: false,
      addressLists: [],
      uuid: '',
    }
  },
  created() {
    this.getSearchList = debounce({
      func: this.getSearchList,
      wait: 50,
    })
    this.uuid = uuidv4()
  },
  destroyed() {
    this.getSearchList.cancel()
  },
  methods: {
    formatHtml(address) {
      const reg = new RegExp(`(${this.keywords})`, 'i')
      return address.replace(reg, '<strong>$1</strong>')
    },
    clear() {
      this.keywords = ''
      this.addressLists = []
      this.isShowLists = false
    },
    async getSearchList() {
      this.$emit('focus')
      if (!this.keywords) {
        this.clear()
        return
      }
      const { country, language } = GOOGLE_DATA?.[this.countryId] ?? {}
      const data = { countries: country, value: this.keywords, type: 'google', language, uuid: this.uuid  }
      const res = await schttp({
        method: 'POST',
        url: '/api/user/addressbook/textSearch/get',
        data,
      })
      const { predictions = [] } = res || {}
      if (this.keywords) {
        this.addressLists =
          predictions?.map?.(({ description: Text = '', place_id: Id = '' }) => ({ Text, Id })) ||
          []
        this.isShowLists = true
      }
    },
    formatAddress({ geometry = {}, address_components = [], formatted_address = '' }) {
      let data = {
        ...(geometry?.location || {}),
      }
      const fields = GOOGLE_DATA?.[this.countryId]?.fields || []
      if (fields.length) {
        GOOGLE_DATA[this.countryId].fields.forEach(([origin, result, name]) => {
          let addressComponents = address_components || []
          // eslint-disable-next-line no-unused-vars
          for (const [key, component] of addressComponents.entries()) {
            if (isFunction(origin)) {
              data[result] = origin({ components: keyBy(addressComponents, (o) => o?.types?.[0]) })
              break
            }
            const isMatch = Array.isArray(origin)
              ? origin.some((item) => component?.types?.includes(item))
              : component?.types?.includes(origin)
            if (isMatch) {
              data[result] = component[name || 'long_name']
              break
            }
          }
        })
      }
      const [address1 = '', address2 = ''] = formatted_address?.split(',') || []
      data.address1 = address1.trim()
      data.address2 = address2.trim()
      const response = mapKeys(data, (_, key) => {
        const keys = {
          City: 'city',
          PostalCode: 'postcode',
        }
        return keys[key] || key
      })
      return response
    },
    async getFormattedData({ Id }) {
      if (this.isLoading || !Id) {
        return
      }
      const cacheData = CACHE.get(Id)
      if (cacheData) {
        return cacheData
      }
      const { language } = GOOGLE_DATA?.[this.countryId] ?? {}
      const data = { id: Id, language, type: 'google', uuid: this.uuid }
      this.isLoading = true
      try {
        const res = await schttp({
          method: 'POST',
          url: '/api/user/addressbook/placeDetails/get',
          data,
        })
        this.uuid = uuidv4()
        this.isLoading = false
        const addressInfo = this.formatAddress(res?.result || {})
        if (Object.keys(data).length) {
          CACHE.set(Id, addressInfo)
        }
        return addressInfo
      } catch (error) {
        this.isLoading = false
        return {}
      }
    },
    async tap(item) {
      const data = await this.getFormattedData(item)
      this.isShowLists = false
      this.$emit('select', data)
    },
  },
}
</script>
<style lang="less" scoped>
.c-search {
  position: relative;
  &__panel {
    padding: 0.16rem 0.32rem;
    background: #fff;
  }
  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f6f6f6;
    .content {
      &::-webkit-input-placeholder {
        /* WebKit browsers*/
        .font-dpr(24px);
        color: #ccc !important; /* stylelint-disable-line declaration-no-important */
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        .font-dpr(24px);
        color: #ccc !important; /* stylelint-disable-line declaration-no-important */
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        .font-dpr(24px);
        color: #ccc !important; /* stylelint-disable-line declaration-no-important */
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+*/
        .font-dpr(24px);
        color: #ccc !important; /* stylelint-disable-line declaration-no-important */
      }
      flex: 1;
      padding-right: 0.187rem;
      height: 100%;
      .font-dpr(28px);
      border: none;
      background: #f6f6f6;
    }
    .sui_icon_nav_search_24px {
      padding: 0 0.267rem;
      color: #767676;
    }
    .icon-clear1 {
      margin-right: 0.267rem;
      color: #767676;
    }
  }
  &__list {
    position: absolute;
    .left(0);
    z-index: @zindex-hack;
    color: #222;
    .font-dpr(28px);
    width: 100%;
    background: #fff;
    .panel {
      padding: 0 15px;
    }
    .service {
      padding: 0.13rem 0.32rem;
      text-align: right;
      .logo {
        width: 2.61rem;
        height: auto;
      }
    }
    .item {
      padding: 0.347rem 0;
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      .text {
        display: block;
        padding: 0 0.32rem;
      }
    }
  }
  &__result {
    position: relative;
    height: 100%;
    overflow-y: scroll;
  }
}
</style>
